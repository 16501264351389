import React from 'react';

import Layout from '../layouts';
import Container from '../components/Container';
import Header from '../components/Basket/header';
import Basket from '../components/Basket';

export default function BasketPage({ location }) {
  const page = { name: 'My Basket', slug: 'basket' };
  const prevPage = location.state && location.state.prevPage;
  return (
    <Layout
      title={page.name}
      location={`/${page.slug}/`}
      prevPage={prevPage}
      cart
    >
      <Header content="Your basket is empty" />
      <Container>
        <Basket />
      </Container>
    </Layout>
  );
}
