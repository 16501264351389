import React from 'react';

import { withCart } from '../Cart/context';
import PageTitle from '../Subpage/title';

export default withCart(function BasketHeader({ content, count }) {
  const title =
    count > 0 ? `${count} Item${count > 1 ? 's' : ''} in Basket` : content;
  return <PageTitle title={title} />;
});
