import React from 'react';

import { withCart } from '../Cart/context';

import Row from '../Container/row';
import Col from '../Container/column';
import Placeholder from '../Placeholder';

import { MainColour } from '../../utils/variables';
import { ItemsPlaceholder, SummaryPlaceholder } from './placeholders';

import loadComponents from '../Loadable';

const Summary = loadComponents('basket-summary');
const Items = loadComponents('basket-items');
const Button = loadComponents('button');

export default withCart(function Basket({ count }) {
  return count > 0 ? (
    <Row justify="space-between">
      <Col xxl={{ width: '75%' }}>
        <Items fallback={<ItemsPlaceholder />} />
      </Col>
      <Col xxl={{ width: '22%' }}>
        <Summary fallback={<SummaryPlaceholder />} />
      </Col>
    </Row>
  ) : (
    <Button
      to="/"
      primary
      style={{ width: `350px`, maxWidth: `100%` }}
      fallback={
        <Placeholder
          width="350px"
          height="58px"
          colour={MainColour}
          style={{ borderRadius: `100px` }}
        />
      }
    >
      Return to Homepage
    </Button>
  );
});
