/* eslint-disable import/prefer-default-export */
import React from 'react';
import { MainColour } from '../../utils/variables';

import Placeholder from '../Placeholder';

export const DeletePlaceholder = () => (
  <Placeholder className="logo" width="20px" height="20px" colour="#222" />
);

export const ItemsPlaceholder = () => (
  <div
    style={{
      display: `flex`,
      flexDirection: `column`,
    }}
  >
    <div className="single">
      <div className="details">
        <Placeholder
          width="200px"
          height="32px"
          colour="#222"
          style={{ marginBottom: `14px` }}
        />
        <Placeholder
          width="100px"
          height="16px"
          colour="#222"
          style={{ marginBottom: `7px` }}
        />
        <Placeholder width="120px" height="16px" colour="#222" />
      </div>
      <div className="price" />
      <div className="quantity" />
      <Placeholder
        width="100px"
        height="24px"
        colour="#222"
        className="subtotal"
      />
      <Placeholder
        width="20px"
        height="20px"
        colour="#222"
        className="delete"
      />
    </div>
  </div>
);

export const CheckoutButton = () => (
  <Placeholder
    width="100%"
    height="58px"
    colour={MainColour}
    style={{ borderRadius: `100px`, minWidth: `255px` }}
  />
);

export const EmptyButton = () => (
  <Placeholder
    width="100px"
    height="22px"
    colour="#222"
    style={{ margin: `1.875rem auto 0` }}
  />
);

export const SummaryPlaceholder = () => (
  <div className="basketSummary">
    <div className="basketTotal">
      <Placeholder
        width="100px"
        height="18px"
        colour="#222"
        style={{ marginBottom: `11px` }}
      />
      <Placeholder
        width="200px"
        height="40px"
        colour="#222"
        style={{ marginBottom: `2.8125rem` }}
      />
    </div>
    <div className="basketActions">
      <CheckoutButton />
      <EmptyButton />
    </div>
  </div>
);
